import React, { createContext, useContext, useMemo, useState } from 'react';

const initialData = {
  selectedBanner: null,
  isChurnedBanner: true,
  isRenewalBanner: true,
  isRegistrationPresent: null,
};

export const NotificationBannerContext = createContext(initialData);

const NotificationBannerProvider = props => {
  const [selectedBanner, setSelectedBanner] = useState(initialData.selectedBanner);
  const [isChurnedBanner, setChurnedBanner] = useState(initialData.isChurnedBanner);
  const [isRenewalBanner, setRenewalBanner] = useState(initialData.isRenewalBanner);
  const [isRegistrationPresent, setIsRegistrationPresent] = useState(initialData.pendingAccessPopup);

  const dataValue = useMemo(
    () => ({
      selectedBanner,
      setSelectedBanner,
      isChurnedBanner,
      setChurnedBanner,
      isRenewalBanner,
      setRenewalBanner,
      isRegistrationPresent,
      setIsRegistrationPresent,
    }),
    [selectedBanner, isChurnedBanner, isRenewalBanner, isRegistrationPresent],
  );

  return <NotificationBannerContext.Provider value={dataValue} {...props} />;
};

export const useNotificationBanner = () => useContext(NotificationBannerContext);

export default NotificationBannerProvider;
