import React, { useEffect, useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { useSubscriptionMissingPMBanner } from 'components/NotificationBanner/banners/SubscriptionMissingPMBanner/hook';
import { useAnnualSubscriptionRenewalBanner } from 'components/NotificationBanner/banners/SubscriptionRenewalBanner/hook';

import usePageContext from 'utils/hooks/usePageContext';

import { NOTIFICATION_BANNER } from 'constants/bannerConstants';
import { route } from 'constants/routes';
import useAppContext from 'utils/hooks/useAppContext';

const MaintenanceBanner = dynamic(() => import('./banners/MaintenanceBanner'));
const LsVodBanner = dynamic(() => import('./banners/LsVodBanner'));
const SubscriptionMissingPMBanner = dynamic(() => import('./banners/SubscriptionMissingPMBanner'));
const SubscriptionRenewalBanner = dynamic(() => import('./banners/SubscriptionRenewalBanner'));

const NotificationBanner = () => {
  const router = useRouter();
  const { permissions } = usePageContext();
  const { isTrustedBot } = useAppContext();
  const { isAdmin } = permissions;

  const isSubscriptionMissingPMOpen = useSubscriptionMissingPMBanner();
  const [isActiveRenewalOpen, isCancelledRenewalOpen] = useAnnualSubscriptionRenewalBanner();
  const [isDisplayed, setIsDisplayed] = useState(false);

  useEffect(() => {
    // Suspending banners in SSR so localstorage logic can be run first
    setIsDisplayed(true);
  }, []);

  const hasRegistrationRoute = route.REGISTER === router.route;
  const displayedBanner = useMemo(() => {
    if (hasRegistrationRoute || !isDisplayed || isAdmin) {
      return null;
    }
    if (process.env.MAINTENANCE_MESSAGE_DISPLAYED === 'true' && !isTrustedBot) return NOTIFICATION_BANNER.MAINTENANCE;
    if (process.env.LS_VOD_NOTIFICATION_BANNER === 'true') return NOTIFICATION_BANNER.LS_VOD;
    if (isSubscriptionMissingPMOpen) {
      return NOTIFICATION_BANNER.SUB_NO_PM;
    }

    if (isActiveRenewalOpen || isCancelledRenewalOpen) return NOTIFICATION_BANNER.RENEWAL;

    return null;
  }, [
    isTrustedBot,
    hasRegistrationRoute,
    isDisplayed,
    isAdmin,
    isSubscriptionMissingPMOpen,
    isActiveRenewalOpen,
    isCancelledRenewalOpen,
  ]);

  switch (displayedBanner) {
    case NOTIFICATION_BANNER.MAINTENANCE:
      return <MaintenanceBanner />;

    case NOTIFICATION_BANNER.LS_VOD:
      return <LsVodBanner />;

    case NOTIFICATION_BANNER.SUB_NO_PM:
      return <SubscriptionMissingPMBanner />;

    case NOTIFICATION_BANNER.RENEWAL:
      return <SubscriptionRenewalBanner active={isActiveRenewalOpen} cancelled={isCancelledRenewalOpen} />;

    default:
      return null;
  }
};

export default NotificationBanner;
