export const NOTIFICATION_BANNER = {
  ARTIST_NO_SUBSCRIPTION: 'artist_no_subscription',
  ARTIST_NO_PRODUCTION: 'artist_no_production',
  ARTIST_PENDING_ACCESS: 'artist_pending_access',
  CHURNED: 'churned',
  RENEWAL: 'renewal',
  SUB_NO_PM: 'sub_no_pm',
  MAINTENANCE: 'maintenance',
  NEW_REGISTRATION: 'new_registration',
  LS_VOD: 'ls_vod',
  ARTIST_NOSUB_NOID: 'artist_nosub_noid',
  ARTIST_NOSUB_HASID: 'artist_nosub_hasid',
  ARTIST_NOSUB_HASID_EXHAUSTED_TRIAL: 'artist_nosub_hasid_exhausted_trial',
  ARTIST_NO_PRODUCTIONS_NO_INFO: 'artist_no_productions_no_info',
  ARTIST_MISSING_INFO: 'artist_missing_info',
  ARTIST_NO_PRODUCTIONS_HAS_INFO: 'artist_no_productions_has_info',
  ARTIST_HAS_GREY_MASK: 'artist_has_grey_mask',
  ARTIST_NO_PHOTO: 'artist_no_photo',
  ARTIST_NO_AUDITION: 'artist_no_audition',
  ARTIST_NO_FUTURE_REPERTOIRE: 'artist_no_future_repertoire',
  ARTIST_NO_REPRESENTATION: 'artist_no_representation',
  ARTIST_CHURNED: 'artist_churned',
  AOS_PROVIDE_INFO: 'aos_provide_info',
  AOS_PENDING_ACCESS: 'aos_pending_access',
  AOS_CT_EXPIRED: 'aos_ct_expired',
  AOS_PRPRO_EXPIRED: 'aos_prpro_expired',
  AOS_EXPIRED_SUB_NOT_ADMIN: 'aos_expired_sub_not_admin',
  AOS_SUBSCRIPTION_RENEWS_SOON: 'aos_subscription_renews_soon',
  RENTALS_BANNER: 'rentals_banner',
};

export const BANNERS_TRACKING_ID = {
  [NOTIFICATION_BANNER.ARTIST_NOSUB_NOID]: 'banner_artist_cta_verify_id',
  [NOTIFICATION_BANNER.ARTIST_NOSUB_HASID]: 'banner_artist_cta_start_trial',
  [NOTIFICATION_BANNER.ARTIST_NOSUB_HASID_EXHAUSTED_TRIAL]: 'banner_artist_cta_start_subscription',
  [NOTIFICATION_BANNER.ARTIST_NO_PRODUCTIONS_NO_INFO]: 'banner_artist_cta_add_production_not_updated_prof',
  [NOTIFICATION_BANNER.ARTIST_MISSING_INFO]: 'banner_artist_cta_edit_profile',
  [NOTIFICATION_BANNER.ARTIST_NO_PRODUCTIONS_HAS_INFO]: 'banner_artist_cta_add_production',
  [NOTIFICATION_BANNER.ARTIST_HAS_GREY_MASK]: 'banner_artist_cta_grey_mask',
  [NOTIFICATION_BANNER.ARTIST_NO_PHOTO]: 'banner_artist_cta_no_photo',
  [NOTIFICATION_BANNER.ARTIST_NO_AUDITION]: 'banner_artist_cta_no_audition',
  [NOTIFICATION_BANNER.ARTIST_NO_FUTURE_REPERTOIRE]: 'banner_artist_cta_no_future_repertoire',
  [NOTIFICATION_BANNER.ARTIST_NO_REPRESENTATION]: 'banner_artist_cta_no_representation',
  [NOTIFICATION_BANNER.ARTIST_CHURNED]: 'banner_artist_cta_reactivate_subscription',
};

export const BANNERS_BEHAVIOUR_CONFIG = [
  { name: NOTIFICATION_BANNER.ARTIST_NOSUB_NOID, closeLimit: 0, isHiddenWhenOverLimit: false },
  { name: NOTIFICATION_BANNER.ARTIST_NOSUB_HASID, closeLimit: 1, closedTimeoutDays: 1 },
  { name: NOTIFICATION_BANNER.ARTIST_NOSUB_HASID_EXHAUSTED_TRIAL, closeLimit: 1, closedTimeoutDays: 1 },
  {
    name: NOTIFICATION_BANNER.ARTIST_NO_PRODUCTIONS_NO_INFO,
  },
  { name: NOTIFICATION_BANNER.ARTIST_MISSING_INFO, isHiddenWhenOverLimit: true },
  {
    name: NOTIFICATION_BANNER.ARTIST_NO_PRODUCTIONS_HAS_INFO,
  },
  { name: NOTIFICATION_BANNER.ARTIST_HAS_GREY_MASK, isHiddenWhenOverLimit: true },
  { name: NOTIFICATION_BANNER.ARTIST_NO_PHOTO, isHiddenWhenOverLimit: true },
  { name: NOTIFICATION_BANNER.ARTIST_NO_AUDITION, isHiddenWhenOverLimit: true },
  {
    name: NOTIFICATION_BANNER.ARTIST_NO_FUTURE_REPERTOIRE,
  },
  {
    name: NOTIFICATION_BANNER.ARTIST_NO_REPRESENTATION,
  },
  { name: NOTIFICATION_BANNER.ARTIST_CHURNED, isHiddenWhenOverLimit: true },
  { name: NOTIFICATION_BANNER.AOS_PROVIDE_INFO, isHiddenWhenOverLimit: true },
  { name: NOTIFICATION_BANNER.AOS_PENDING_ACCESS, isHiddenWhenOverLimit: true },
  { name: NOTIFICATION_BANNER.AOS_CT_EXPIRED, isHiddenWhenOverLimit: true },
  { name: NOTIFICATION_BANNER.AOS_PRPRO_EXPIRED, isHiddenWhenOverLimit: true },
  { name: NOTIFICATION_BANNER.NEW_REGISTRATION, isHiddenWhenOverLimit: true },
  {
    name: NOTIFICATION_BANNER.AOS_EXPIRED_SUB_NOT_ADMIN,
  },
  {
    name: NOTIFICATION_BANNER.AOS_SUBSCRIPTION_RENEWS_SOON,
  },
];
